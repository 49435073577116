import React from 'react';
import './searchBox.css';
import { CiSearch } from "react-icons/ci";

const SearchBox = ({setSortText, setSearchText}) => {
  return (
    <div className='press-search-box-container'>
      <div className='press-search-box-left'>
        <input type="text" placeholder='Search' onChange={(e)=> setSearchText(e.target.value)}/>
        <CiSearch className='press-search-box-left-icon' size={20}/>
      </div>
      <div className='press-search-box-right'>
        <select onChange={(e)=>setSortText(e.target.value)}>
            <option value="1">Latest</option>
            <option value="2">Oldest</option>
        </select>
      </div>
    </div>
  )
}

export default SearchBox
