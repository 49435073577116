import React from 'react'
import "./pressReleaseMainBanner.css"
import PressReleaseBG from "../../images/press_release_bg.webp"

export default function PressReleaseMainBanner() {
    return (
        <div>
            <div>
                <div style={{ position: "relative" }}>
                    <img className="press-release-home-image" alt="" src={PressReleaseBG} />
                    <div className="press-release-image-text-box">
                    <div className="press-release-image-background-text">
                        <h1 className="press-release-image-topic">
                            Press Release
                        </h1>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
