import React, { useState } from 'react'
import PressReleaseMainBanner from './pressReleaseMainBanner'
import SearchBox from './searchBox'
import PressReleaseMainContent from './pressReleaseMainContent'

const PressReleaseHome = () => {
    
    const [searchText, setSearchText] = useState('');
    const [sortText, setSortText] = useState(1);
  return (
    <>
     <PressReleaseMainBanner />
      <SearchBox 
        setSearchText={setSearchText}
        setSortText={setSortText}
      />
      <PressReleaseMainContent 
        searchText={searchText}
        sortText={sortText}
      />
    </>
  )
}

export default PressReleaseHome
